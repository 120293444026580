import {
  KnifeConfig,
  KnifeName,
  Metal,
  Wood,
  KnifeVersatility,
  KnifeGrindingProfile,
  KnifePart,
} from '~src/types/knife'
import { KlosySVGId } from '~components/homepage/svg/types/svg'
import { LightAsianCleaver } from '~components/homepage/svg/icons/LightAsianCleaver'

const knife: KnifeConfig = {
  id: '4',
  name: KnifeName.LightAsianCleaver,
  title: { PL: 'Lekki tasak azjatycki', EN: 'Light Asian cleaver' },
  description: {
    PL: [
      'Te bardzo szerokie i\u00A0lekkie ostrza nawiązują do tradycyjnych noży azjatyckich. Lekkie tasaki to noże o bardzo szerokim zastosowaniu, mogą być podstawowym ostrzem kuchennym - przy czym wymagają odrobiny wprawy i\u00A0przyzwyczajenia. W\u00A0tak szerokich ostrzach stosujemy niesymetryczne szlify ( tzw. Single bevel), które zapobiegają przyklejaniu się krojonych produktów do ostrza. Szerokie ostrze zapewnia dużo miejsca do oparcia palców przy krojeniu, może też służyć jako ‘łopatka’ przy przenoszeniu krojonych produktów z\u00A0deski. ',
    ],
    EN: [
      'Very wide and light, these blades are inspired by traditional asian knives. Light cleavers have a\u00A0variety of applications, they can be your basic kitchen blade, although they might need a\u00A0bit of practice and getting used to. Such wide blades utilize asymmetrical cuts (“single bevel”) that prevent food from sticking to the blade. The width of the blade provides a\u00A0lot of space to put your fingers on while leading the blade and can be used as a\u00A0spatula for moving cut products from the board.',
    ],
  },
  length: 195,
  height: 70,
  weight: 250,
  versatility: KnifeVersatility.four,
  grindingProfile: [KnifeGrindingProfile.asymSingleBevel],
  svgPath: KlosySVGId.lightAsianCleaver,
  SvgKnife: LightAsianCleaver,
  image: 'https://klosy.pl/grafika/sylwetki210px/new/pealing g.png',
  priceRow: {
    PL: 2600,
    EN: 570,
  },
  price: {
    PL: '2600 zł',
    EN: '570 €',
  },
  pricingTable: {
    [KnifePart.Blade]: [
      { type: Metal.Damasteel, price: 0 },
      { type: Metal.K110, price: 100 },
      { type: Metal.N690, price: 0 },
      { type: Metal.M398, price: 0 },
    ],
    [KnifePart.Handle]: [
      { type: Wood.Ironwood, price: 0 },
      { type: Wood.Ebony, price: 0 },
      { type: Wood.Olivewood, price: 0 },
      { type: Wood.PaintedWood, price: 0 },
      { type: Wood.Walnut, price: 0 },
    ],
    [KnifePart.Ferrules]: [{ type: Metal.Damasteel, price: 0 }],
  },
}

export default knife
