import React from 'react'
import { KlosySVGId } from '~components/homepage/svg/types/svg'

// Welcome to write documentation here

/*
 *         FerrulesComp.
 *             |
 *             |
 *
 *      [######|/////////////>       <----- KnifeConfig (whole knife)
 *        |             |
 *        |             |
 *        |             |
 *     HandleComp.     BladeComp.
 *
 *
 */

// INTERFACES BELOW
type Text = string | string[]

export enum LangCodes {
  en = 'EN',
  pl = 'PL',
}

export interface Localization {
  PL: Text | number
  EN: Text | number
}

export interface KnifeConfig {
  id: string
  name: KnifeName
  title: Localization
  description?: Localization
  pricingTable: PricingTable
  price: Localization
  priceRow: Localization
  length: number
  lengthInch?: number
  height: number
  weight: number
  versatility: KnifeVersatility
  grindingProfile: KnifeGrindingProfile[]
  image?: string // TODO: remove and replace with svg
  svg?: () => React.ReactElement
  SvgKnife: React.FC
  svgPath?: KlosySVGId
}

export interface WoodConfig {
  id: string
  name: WoodName
  title: Localization
  description?: Localization
  price: Localization
  priceRow: Localization
  care: Localization
  oilCare: Localization
  endurance: MaterialEndurance
  weight: Localization
  img?: string
}

export interface MetalConfig {
  id: string
  name: MetalName
  title: Localization
  description?: Localization
  price: Localization
  priceRow: Localization
  img?: string
  stainless: SteelStainless
  sharpening: Localization
  hardness: MaterialEndurance
  edgeRetention?: EdgeRetention
  coreStainless?: SteelStainless
}

export enum KnifePart {
  Blade = 'blade',
  Ferrules = 'ferrules',
  Handle = 'handle',
}

// TODO: generic Components<T>
export interface BladeComponent {
  type: SteelType
  name: Localization
  description: Localization
  sharpening: SteelSharpening
  stainless: SteelStainless
}

export interface HandleComponent {
  type: HandleType
  name: Localization
  description: Localization
  weight: MaterialWeight
  endurance: MaterialEndurance
}

export interface FerrulesComponent {
  type: FerrulesType
  name: Localization
  description: Localization
  weight: MaterialWeight
  endurance: MaterialEndurance
}

export interface Pricing<PartType> {
  type: PartType
  price: number
}

export interface PricingTable {
  [KnifePart.Blade]: Pricing<SteelType>[]
  [KnifePart.Handle]: Pricing<HandleType>[]
  [KnifePart.Ferrules]: Pricing<FerrulesType>[]
}

// TYPES BELOW

export type Material = Metal | Wood
export type PickedKnives = KnifeName[]

export type SteelType = Metal.Damasteel | Metal.K110 | Metal.M398 | Metal.N690
export type HandleType = Wood
export type FerrulesType =
  | Metal.Stainless
  | Metal.Brass
  | Metal.Titanium
  | Metal.Damasteel

export type PartType = SteelType | HandleType | FerrulesType

export type TheSetConfiguration = { [value in KnifePart]: PartType }

export interface Preset {
  title: Localization
  knives: KnifeConfig[]
}

// ENUMS BELOW

export enum KnifeName {
  Chef240 = 'Chef240',
  Chef210 = 'Chef210',
  Santoku = 'Santoku',
  Filleting = 'Filleting',
  Boning = 'Boning',
  Serrated = 'Serrated',
  Peeling = 'Peeling',
  LightAsianCleaver = 'LightAsianCleaver',
  Cleaver = 'Cleaver',
  Yanagiba = 'Yanagiba',
  Punching = 'Punching',
}

export enum WoodName {
  Ironwood = 'Ironwood',
  Olea = 'Olea',
  Heban = 'Heban',
  Juglans = 'Juglans',
  Paintedwood = 'Paintedwood',
  Snakewood = 'Snakewood',
}

export enum MetalName {
  K110 = 'K110',
  M398 = 'M398',
  M390 = 'M390',
  N690 = 'N690',
  SanMai = 'SanMai',
  Damasteel = 'Damasteel',
}

export enum KnifeVersatility {
  one = 1,
  two = 2,
  three = 3,
  four = 4,
  five = 5,
}

export enum KnifeGrindingProfile {
  symFullFLat = 'Sym full flat',
  asymSingleBevel = 'Asym single bevel',
  symFlatTapered = 'Sym flat tapered',
  lowFlat = 'Low flat',
  convex = 'Convex',
  halfGrind = 'Half grind',
  sgrind = 'S-grind',
}

export enum Metal {
  Damasteel = 'damasteel',
  Brass = 'brass',
  Titanium = 'titanium',
  Stainless = 'stainless',
  K110 = 'k110',
  N690 = 'N690',
  M398 = 'M398',
}

export enum Wood {
  Ebony = 'ebony',
  Ironwood = 'ironwood',
  Olivewood = 'olivewood',
  PaintedWood = 'paintedWood',
  Walnut = 'walnut',
}

export enum MaterialWeight {
  Light = 'light',
  Medium = 'medium',
  Heavy = 'heavy',
}

export enum MaterialEndurance {
  one = 1,
  two = 2,
  three = 3,
  four = 4,
  five = 5,
}

export enum SteelSharpening {
  easy = 'easy',
  hard = 'hard',
}

export enum SteelStainless {
  one = 1,
  two = 2,
  three = 3,
  four = 4,
  five = 5,
}

export enum EdgeRetention {
  one = 1,
  two = 2,
  three = 3,
  four = 4,
  five = 5,
}
