import {
  KnifeConfig,
  KnifeName,
  Metal,
  Wood,
  KnifeVersatility,
  KnifeGrindingProfile,
  KnifePart,
} from '~src/types/knife'
import { KlosySVGId } from '~components/homepage/svg/types/svg'
import { Punching } from '~components/homepage/svg/icons/punching'

const knife: KnifeConfig = {
  id: '11',
  name: KnifeName.Punching,
  title: { PL: 'Nóż do wykrawania', EN: 'Cut-out knife' },
  description: {
    PL: [
      'Wąskie ostrze, długości 165mm zakończone ostrym czubkiem, pozwala na precyzyjną pracę przy przygotowaniu mięsa, krojeniu warzyw i\u00A0owoców. Noże do wykrawania świetnie sprawdzają się w\u00A0zastosowaniach wymagających dużej precyzji cięcia, w\u00A0których noże szefa kuchni będą za duże i\u00A0nieporęczne. W\u00A0odróżnieniu od noży szefa, wykrawacze mają zbyt wąskie ostrza żeby wygodnie pracować nimi na desce - to idealne ostrze uzupełniające dla dużych noży.',
    ],
    EN: [
      'Narrow, 165 mm long, blade with a\u00A0sharp tip allows for precise operations when preparing meat or cutting vegetables and fruit. These knives come in handy when high precision is necessary and when the chef’s knives are too big and bulky. The blades in cut-out knives are too narrow to use comfortably on the cutting board – they are a\u00A0perfect addition to big knives, though.',
    ],
  },
  length: 165,
  height: 33,
  weight: 110,
  versatility: KnifeVersatility.five,
  grindingProfile: [KnifeGrindingProfile.symFullFLat],
  image: 'https://klosy.pl/grafika/sylwetki210px/new/pealing g.png',
  svgPath: KlosySVGId.punching,
  SvgKnife: Punching,
  priceRow: {
    PL: 1450,
    EN: 320,
  },
  price: {
    PL: '1450 zł',
    EN: '320 €',
  },
  pricingTable: {
    [KnifePart.Blade]: [
      { type: Metal.Damasteel, price: 850 },
      { type: Metal.K110, price: 50 },
      { type: Metal.N690, price: 0 },
      { type: Metal.M398, price: 0 },
    ],
    [KnifePart.Handle]: [
      { type: Wood.Ironwood, price: 0 },
      { type: Wood.Ebony, price: 0 },
      { type: Wood.Olivewood, price: 0 },
      { type: Wood.PaintedWood, price: 0 },
      { type: Wood.Walnut, price: 0 },
    ],
    [KnifePart.Ferrules]: [{ type: Metal.Damasteel, price: 0 }],
  },
}

export default knife
