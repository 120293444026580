import {
  KnifeConfig,
  KnifeName,
  Metal,
  Wood,
  KnifeVersatility,
  KnifeGrindingProfile,
  KnifePart,
} from '~src/types/knife'
import { KlosySVGId } from '~components/homepage/svg/types/svg'
import { Santoku } from '~components/homepage/svg/icons/santoku'

const knife: KnifeConfig = {
  id: '3',
  name: KnifeName.Santoku,
  title: {
    PL: 'Nóż do warzyw i ziół / Santoku',
    EN: 'Vegetable knife / Santoku',
  },
  description: {
    PL: [
      'To uniwersalne ostrze inspirowane Japońskimi nożami tzw. Santoku. To szerokie ostrza o prawie płaskiej linii krawędzi tnącej. Można stosować je jako podstawowe ostrze w\u00A0kuchni - zamiennie z\u00A0nożem szefa. Natomiast, ze względu na kształt krawędzi przewidziane są głównie do krojenia ruchem góra - dół, nie nadają się do pracy z\u00A0czubkiem ostrza na desce. Szerokie ostrze zapewnia dużo miejsca do oparcia palców przy prowadzeniu ostrza, może też służyć jako ‘łopatka’ przy przenoszeniu krojonych produktów z\u00A0deski.',
    ],
    EN: [
      'This universal knife is inspired by Japanese Santoku knives. They utilize wide blades with an almost flat cutting edge line. They can be used as basic blades in the kitchen, interchangeably with the chef’s knife. However, because of the edge’s shape, they are mostly used for chopping in an up-and-down motion and their tip can’t be leaned against the board. The width of the blade provides a\u00A0lot of space to put your fingers on while leading the blade and can be used as a\u00A0spatula for moving cut products from the board.',
    ],
  },
  length: 205,
  height: 56,
  weight: 195,
  versatility: KnifeVersatility.four,
  grindingProfile: [
    KnifeGrindingProfile.symFullFLat,
    KnifeGrindingProfile.asymSingleBevel,
    KnifeGrindingProfile.convex,
    KnifeGrindingProfile.sgrind,
  ],
  image: 'https://klosy.pl/grafika/sylwetki210px/new/vege g.png',
  svgPath: KlosySVGId.santoku,
  SvgKnife: Santoku,
  priceRow: {
    PL: 2100,
    EN: 460,
  },
  price: {
    PL: '2100 zł',
    EN: '460 €',
  },
  pricingTable: {
    [KnifePart.Blade]: [
      { type: Metal.Damasteel, price: 1550 },
      { type: Metal.K110, price: 100 },
      { type: Metal.N690, price: 0 },
      { type: Metal.M398, price: 0 },
    ],
    [KnifePart.Handle]: [
      { type: Wood.Ironwood, price: 0 },
      { type: Wood.Ebony, price: 0 },
      { type: Wood.Olivewood, price: 0 },
      { type: Wood.PaintedWood, price: 0 },
      { type: Wood.Walnut, price: 0 },
    ],
    [KnifePart.Ferrules]: [{ type: Metal.Damasteel, price: 0 }],
  },
}

export default knife
