import {
  KnifeConfig,
  KnifeName,
  Metal,
  Wood,
  KnifeVersatility,
  KnifeGrindingProfile,
  KnifePart,
} from '~src/types/knife'
import { KlosySVGId } from '~components/homepage/svg/types/svg'
import { Chef240 } from '~components/homepage/svg/icons/chef240'

const knife: KnifeConfig = {
  id: '2',
  name: KnifeName.Chef240,
  title: { PL: 'Nóż szefa kuchni 240 mm', EN: "Chef's knife 240mm" },
  description: {
    PL: [
      'Nóż szefa 240mm to przedłużona wersja noża szefa kuchni, warto ją wybrać jeśli pracuje się często w\u00A0większych warzywach, lubi kroić opierając czubek noża na desce albo po prostu lubi się dłuższe noże - kwestia doboru długości ostrza to bardzo indywidualna sprawa.',
    ],
    EN: [
      'This is an extended version of the chef’s knife, worth choosing if you often work with larger vegetables, cut with the top of the knife leaned against the board, or if you simply prefer longer knives – the length of the blade is a\u00A0purely individual matter.',
    ],
  },
  image: 'https://klosy.pl/grafika/sylwetki210px/new/szef g.png',
  svgPath: KlosySVGId.chef240,
  SvgKnife: Chef240,
  length: 240,
  lengthInch: 9,
  height: 55,
  weight: 205,
  versatility: KnifeVersatility.four,
  grindingProfile: [
    KnifeGrindingProfile.symFullFLat,
    KnifeGrindingProfile.convex,
    KnifeGrindingProfile.sgrind,
  ],
  priceRow: {
    PL: 2200,
    EN: 480,
  },
  price: {
    PL: '2200 zł',
    EN: '480 €',
  },
  pricingTable: {
    [KnifePart.Blade]: [
      { type: Metal.Damasteel, price: 1650 },
      { type: Metal.K110, price: 100 },
      { type: Metal.N690, price: 0 },
      { type: Metal.M398, price: 0 },
    ],
    [KnifePart.Handle]: [
      { type: Wood.Ironwood, price: 0 },
      { type: Wood.Ebony, price: 0 },
      { type: Wood.Olivewood, price: 0 },
      { type: Wood.PaintedWood, price: 0 },
      { type: Wood.Walnut, price: 0 },
    ],
    [KnifePart.Ferrules]: [{ type: Metal.Damasteel, price: 0 }],
  },
}

export default knife
