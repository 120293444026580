import {
  KnifeConfig,
  KnifeName,
  Metal,
  Wood,
  KnifeVersatility,
  KnifeGrindingProfile,
  KnifePart,
} from '~src/types/knife'
import { KlosySVGId } from '~components/homepage/svg/types/svg'
import { Chef210 } from '~components/homepage/svg/icons/chef210'

const knife: KnifeConfig = {
  id: '1',
  name: KnifeName.Chef210,
  title: { PL: 'Nóż szefa kuchni 210 mm', EN: "Chef's knife 210mm" },
  description: {
    PL: [
      'Nóż szefa kuchni 210 mm (8 cali), to podstawowe narzędzie w\u00A0każdej kuchni. Pozwala na wykonanie wszystkich prac kuchennych związanych z\u00A0cięciem, porcjowaniem, szatkowaniem…',
      'Szerokie ostrze pozwala na wygodną pracę i\u00A0stosowanie różnych technik krojenia. Krawędź tnąca biegnąca delikatnym łukiem, przy czubku noża wykrzywia się mocniej ku górze, co\u00A0pozwala oprzeć nóż na desce w\u00A0trakcie pracy. Szerokie ostrze zapewnia dużo miejsca do oparcia palców przy prowadzeniu ostrza, może też służyć jako ‘łopatka’ przy przenoszeniu krojonych produktów z\u00A0deski. ',
    ],
    EN: [
      'Chef’s knife 210 mm (8”) is a\u00A0basic tool for every kitchen. It lets you perform all the kitchen operations that have anything to do with cutting, portioning, slicing, etc.',
      'Wide blade ensures comfort and lets you use a\u00A0variety of cutting techniques. The cutting edge is a\u00A0little curvy and by the top of the knife it bends further upwards, which allows you to lean the knife against the cutting board when you need it. The width of the blade provides a\u00A0lot of space to put your fingers on while leading the blade and can be used as a\u00A0spatula for moving cut products from the board.',
    ],
  },
  svgPath: KlosySVGId.chef210,
  SvgKnife: Chef210,
  image: 'https://klosy.pl/grafika/sylwetki210px/new/pealing g.png',
  length: 210,
  lengthInch: 8,
  height: 55,
  weight: 190,
  versatility: KnifeVersatility.five,
  grindingProfile: [
    KnifeGrindingProfile.symFullFLat,
    KnifeGrindingProfile.convex,
    KnifeGrindingProfile.sgrind,
  ],
  priceRow: {
    PL: 2100,
    EN: 460,
  },
  price: {
    PL: '2100 zł',
    EN: '460 €',
  },
  pricingTable: {
    [KnifePart.Blade]: [
      { type: Metal.Damasteel, price: 1450 },
      { type: Metal.K110, price: 100 },
      { type: Metal.N690, price: 0 },
      { type: Metal.M398, price: 0 },
    ],
    [KnifePart.Handle]: [
      { type: Wood.Ironwood, price: 0 },
      { type: Wood.Ebony, price: 0 },
      { type: Wood.Olivewood, price: 0 },
      { type: Wood.PaintedWood, price: 0 },
      { type: Wood.Walnut, price: 0 },
    ],
    [KnifePart.Ferrules]: [{ type: Metal.Damasteel, price: 0 }],
  },
}

export default knife
