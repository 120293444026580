import {
  KnifeConfig,
  KnifeName,
  Metal,
  Wood,
  KnifeVersatility,
  KnifeGrindingProfile,
  KnifePart,
} from '~src/types/knife'
import { KlosySVGId } from '~components/homepage/svg/types/svg'
import { Peeling } from '~components/homepage/svg/icons/peeling'

const knife: KnifeConfig = {
  id: '8',
  name: KnifeName.Peeling,
  title: { PL: 'Nożyk do obierania', EN: 'Paring knife' },
  description: {
    PL: [
      'To bardzo poręczny, wąski nóż o krótkim ostrzu i\u00A0bardzo cienkiej krawędzi tnącej. To jedno z\u00A0uniwersalnych ostrzy, uzupełniające noże szefa kuchni. Oprócz obierania warzyw i\u00A0owoców, nożyki te nadają się do wszystkich kuchennych zadań wymagających dużej precyzji. Pozycja obowiązkowa w\u00A0każdej kuchni.',
    ],
    EN: [
      'A very practical, narrow knife with a\u00A0short blade and thin cutting edge. Very universal, it’s a\u00A0great addition to chef’s knives. Apart from peeling vegetables and fruit, it is also a\u00A0great tool for jobs that require a\u00A0high level of precision.',
    ],
  },
  length: 105,
  height: 26,
  weight: 50,
  versatility: KnifeVersatility.five,
  grindingProfile: [KnifeGrindingProfile.symFullFLat],
  svgPath: KlosySVGId.peeling,
  SvgKnife: Peeling,
  image: 'https://klosy.pl/grafika/sylwetki210px/new/tyci g.png',
  priceRow: {
    PL: 800,
    EN: 170,
  },
  price: {
    PL: '800 zł',
    EN: '170 €',
  },
  pricingTable: {
    [KnifePart.Blade]: [
      { type: Metal.Damasteel, price: 550 },
      { type: Metal.K110, price: 50 },
      { type: Metal.N690, price: 0 },
      { type: Metal.M398, price: 0 },
    ],
    [KnifePart.Handle]: [
      { type: Wood.Ironwood, price: 0 },
      { type: Wood.Ebony, price: 0 },
      { type: Wood.Olivewood, price: 0 },
      { type: Wood.PaintedWood, price: 0 },
      { type: Wood.Walnut, price: 0 },
    ],
    [KnifePart.Ferrules]: [{ type: Metal.Damasteel, price: 0 }],
  },
}

export default knife
