import {
  KnifeConfig,
  KnifeName,
  Metal,
  Wood,
  KnifeVersatility,
  KnifeGrindingProfile,
  KnifePart,
} from '~src/types/knife'
import { KlosySVGId } from '~components/homepage/svg/types/svg'
import { Filleting } from '~components/homepage/svg/icons/filleting'

const knife: KnifeConfig = {
  id: '5',
  name: KnifeName.Filleting,
  title: { PL: 'Nóż do filetowania', EN: 'Filleting knife' },
  description: {
    PL: [
      'To wąskie i\u00A0bardzo elastyczne ostrze, przeznaczone do pracy przy przygotowaniu ryb. Grzbiety noży do filetowania zwężają się równomiernie ku czubkowi, zapewniając proporcjonalne ugięcie ostrza na całej długości. Ze względu na specyfikę pracy w\u00A0mięsie i\u00A0rybach, rękojeść jest oddzielona od ostrza wyraźnie zarysowanym jelcem, który chroni palce przed przypadkowym kontaktem z\u00A0bardzo cienką krawędzią tnącą.',
    ],
    EN: [
      'This narrow and very flexible blade is perfect for preparing fish. Its spine narrows steadily towards the tip so that the whole blade can bend proportionally. Because this knife is mostly used for fish and meat, the handle is separated from the blade with a\u00A0finger guard to avoid accidental contact with the very thin cutting edge.',
    ],
  },
  length: 235,
  height: 20,
  weight: 145,
  versatility: KnifeVersatility.two,
  grindingProfile: [KnifeGrindingProfile.symFlatTapered],
  image: 'https://klosy.pl/grafika/sylwetki210px/new/filet%20c.png',
  svgPath: KlosySVGId.filleting,
  SvgKnife: Filleting,
  priceRow: {
    PL: 1950,
    EN: 430,
  },
  price: {
    PL: '1950 zł',
    EN: '430 €',
  },
  pricingTable: {
    [KnifePart.Blade]: [
      { type: Metal.Damasteel, price: 850 },
      { type: Metal.K110, price: 100 },
      { type: Metal.N690, price: 0 },
      { type: Metal.M398, price: 0 },
    ],
    [KnifePart.Handle]: [
      { type: Wood.Ironwood, price: 0 },
      { type: Wood.Ebony, price: 0 },
      { type: Wood.Olivewood, price: 0 },
      { type: Wood.PaintedWood, price: 0 },
      { type: Wood.Walnut, price: 0 },
    ],
    [KnifePart.Ferrules]: [{ type: Metal.Damasteel, price: 0 }],
  },
}

export default knife
