import {
  KnifeConfig,
  KnifeName,
  Metal,
  Wood,
  KnifeVersatility,
  KnifeGrindingProfile,
  KnifePart,
} from '~src/types/knife'
import { KlosySVGId } from '~components/homepage/svg/types/svg'
import { Cleaver } from '~components/homepage/svg/icons/cleaver'

const knife: KnifeConfig = {
  id: '7',
  name: KnifeName.Cleaver,
  title: { PL: 'Ciężki tasak', EN: 'Heavy cleaver' },
  description: {
    PL: [
      'Te prostokątne i\u00A0bardzo ciężkie noże służą do rąbania kości i\u00A0chrząstek, rozbijania łupin i\u00A0łodyg, radzą sobie też z\u00A0kruszeniem lodu i\u00A0mrożonkami. Niski, symetryczny szlif, specjalnie pogrubiona krawędź tnąca i\u00A0wysokie odpuszczanie podczas obróbki cieplnej, zapewniają bardzo dużą wytrzymałość tych ostrzy.',
    ],
    EN: [
      'These rectangular and very heavy knives are used to chop bones and gristles, break shells and stems, they also do well with crushing ice and cutting frozen foods. Low, asymmetrical cut, specially thickened cutting edge and high tempering during thermal processing ensure high endurance of these blades.',
    ],
  },
  length: 165,
  height: 70,
  weight: 450,
  versatility: KnifeVersatility.one,
  grindingProfile: [KnifeGrindingProfile.lowFlat],
  svgPath: KlosySVGId.cleaver,
  SvgKnife: Cleaver,
  image: 'https://klosy.pl/grafika/sylwetki210px/new/tasak g.png',
  priceRow: {
    PL: 1950,
    EN: 430,
  },
  price: {
    PL: '1950 zł',
    EN: '430 €',
  },
  pricingTable: {
    [KnifePart.Blade]: [
      { type: Metal.Damasteel, price: 0 },
      { type: Metal.K110, price: 0 },
      { type: Metal.N690, price: 0 },
      { type: Metal.M398, price: 0 },
    ],
    [KnifePart.Handle]: [
      { type: Wood.Ironwood, price: 0 },
      { type: Wood.Ebony, price: 0 },
      { type: Wood.Olivewood, price: 0 },
      { type: Wood.PaintedWood, price: 0 },
      { type: Wood.Walnut, price: 0 },
    ],
    [KnifePart.Ferrules]: [{ type: Metal.Damasteel, price: 0 }],
  },
}

export default knife
