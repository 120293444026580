import {
  KnifeConfig,
  KnifeName,
  Metal,
  Wood,
  KnifeVersatility,
  KnifeGrindingProfile,
  KnifePart,
} from '~src/types/knife'
import { KlosySVGId } from '~components/homepage/svg/types/svg'
import { Boning } from '~components/homepage/svg/icons/boning'

const knife: KnifeConfig = {
  id: '9',
  name: KnifeName.Boning,
  title: { PL: 'Nóż do trybowania', EN: 'Boning knife' },
  description: {
    PL: [
      'Te wąskie ostrza, w\u00A0kształcie przypominające noże do filetowania, szlifowane są w\u00A0taki sposób żeby zachować ich jak największą sztywność, co pozwala na precyzyjną pracę czubkiem noża. To ostrza do wykrawania mięs, oddzielania mięsa od kości, prac masarskich itp. ',
    ],
    EN: [
      'The shape of these blades may look similar to filleting knives, but their cross section makes them very stiff, which allows a\u00A0very precise work with its sharp pointed tip. These knives are used for cutting out meat, separating it from the bone, etc.',
    ],
  },
  length: 195,
  height: 16,
  weight: 95,
  versatility: KnifeVersatility.one,
  grindingProfile: [KnifeGrindingProfile.symFullFLat],
  image: 'https://klosy.pl/grafika/sylwetki210px/new/pealing g.png',
  svgPath: KlosySVGId.boning,
  SvgKnife: Boning,
  priceRow: {
    PL: 1650,
    EN: 360,
  },
  price: {
    PL: '1650 zł',
    EN: '360 €',
  },
  pricingTable: {
    [KnifePart.Blade]: [
      { type: Metal.Damasteel, price: 0 },
      { type: Metal.K110, price: 50 },
      { type: Metal.N690, price: 0 },
      { type: Metal.M398, price: 0 },
    ],
    [KnifePart.Handle]: [
      { type: Wood.Ironwood, price: 0 },
      { type: Wood.Ebony, price: 0 },
      { type: Wood.Olivewood, price: 0 },
      { type: Wood.PaintedWood, price: 0 },
      { type: Wood.Walnut, price: 0 },
    ],
    [KnifePart.Ferrules]: [{ type: Metal.Damasteel, price: 0 }],
  },
}

export default knife
