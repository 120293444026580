import {
  KnifeConfig,
  KnifeName,
  Metal,
  Wood,
  KnifeVersatility,
  KnifeGrindingProfile,
  KnifePart,
} from '~src/types/knife'
import { KlosySVGId } from '~components/homepage/svg/types/svg'
import { Yanagiba } from '~components/homepage/svg/icons/yanagiba'

const knife: KnifeConfig = {
  id: '6',
  name: KnifeName.Yanagiba,
  title: {
    PL: 'Nóż do porcjowania',
    EN: 'Carving / Yanagiba knife',
  },
  description: {
    PL: [
      'Te długie i\u00A0wąskie noże przewidziane są do plastrowania dużych porcji mięs i\u00A0szynek. Długie ostrze pozwala na przeprowadzenie pojedynczych długich cięć. Przy zastosowaniu niesymetrycznych szlifów, mogą służyć też do pracy w\u00A0dużych rybach, zamiennie z\u00A0nożami do filetowania.',
    ],
    EN: [
      'These long, narrow knives are used for portioning large pieces of meat and hams. Long blade makes single, long slashes very easy. By utilizing asymmetrical cuts, they can also be used for large fish, interchangeably with filleting knives.',
    ],
  },
  length: 260,
  height: 37,
  weight: 200,
  versatility: KnifeVersatility.two,
  grindingProfile: [
    KnifeGrindingProfile.symFullFLat,
    KnifeGrindingProfile.asymSingleBevel,
  ],
  image: 'https://klosy.pl/grafika/sylwetki210px/new/pealing g.png',
  svgPath: KlosySVGId.yanagiba,
  SvgKnife: Yanagiba,
  priceRow: {
    PL: 1950,
    EN: 430,
  },
  price: {
    PL: '1950 zł',
    EN: '430 €',
  },
  pricingTable: {
    [KnifePart.Blade]: [
      { type: Metal.Damasteel, price: 0 },
      { type: Metal.K110, price: 100 },
      { type: Metal.N690, price: 0 },
      { type: Metal.M398, price: 0 },
    ],
    [KnifePart.Handle]: [
      { type: Wood.Ironwood, price: 0 },
      { type: Wood.Ebony, price: 0 },
      { type: Wood.Olivewood, price: 0 },
      { type: Wood.PaintedWood, price: 0 },
      { type: Wood.Walnut, price: 0 },
    ],
    [KnifePart.Ferrules]: [{ type: Metal.Damasteel, price: 0 }],
  },
}

export default knife
