import { KnifeConfig } from '~src/types/knife'

import Chef240Knife from './_Chef240.knife'
import Chef210Knife from './_Chef210.knife'
import SantokuKnife from './_Santoku.knife'
import FilletingKnife from './_Filleting.knife'
import BoningKnife from './_Boning.knife'
import SerratedKnife from './_Serrated.knife'
import PeelingKnife from './_Peeling.knife'
import LightAsianCleaverKnife from './_LightAsianCleaver.knife'
import CleaverKnife from './_Cleaver.knife'
import YanagibaKnife from './_Yanagiba.knife'
import PunchingKnife from './_Punching.knife'

export default [
  Chef210Knife,
  Chef240Knife,
  SantokuKnife,
  FilletingKnife,
  BoningKnife,
  SerratedKnife,
  PeelingKnife,
  LightAsianCleaverKnife,
  CleaverKnife,
  PunchingKnife,
  YanagibaKnife,
] as KnifeConfig[]
