import {
  KnifeConfig,
  KnifeName,
  Metal,
  Wood,
  KnifeVersatility,
  KnifeGrindingProfile,
  KnifePart,
} from '~src/types/knife'
import { KlosySVGId } from '~components/homepage/svg/types/svg'
import { Serrated } from '~components/homepage/svg/icons/serrated'

const knife: KnifeConfig = {
  id: '10',
  name: KnifeName.Serrated,
  title: { PL: 'Nóż do pieczywa', EN: 'Bread knife' },
  description: {
    PL: [
      'Noże do pieczywa to długie, wąskie ostrza o specjalnie zaprojektowanym ząbkowaniu. Odpowiednio ukształtowane, zaokrąglone zęby wgryzają się z\u00A0łatwością w\u00A0twarde spieczone skórki dzięki czemu nienaruszona zostaje wewnętrzna gąbczasta struktura pieczywa/ciasta. Zęby w\u00A0naszych nożach mają wypukły kształt przez co nie szarpią i\u00A0nie kruszą skórek przy cięciu. Długie ostrze pozwala na pracę w\u00A0dużych bochenkach, a\u00A0także na uzyskanie szerokich cięć przy pojedynczych przeciągnięciach.',
    ],
    EN: [
      'These long and narrow blades have a\u00A0specially designed serration. Properly shaped, rounded teeth easily bite into hard, parched crust without damaging the inner, sponge-like structure of the bread/cake. The teeth in our knives have a\u00A0convex shape so that they do not shred or crush the crust. Long blade comes in handy when dealing with large loaves and is perfect for wide cuts using single stretches.',
    ],
  },
  length: 280,
  height: 43,
  weight: 210,
  versatility: KnifeVersatility.three,
  grindingProfile: [KnifeGrindingProfile.asymSingleBevel],
  svgPath: KlosySVGId.serrated,
  SvgKnife: Serrated,
  image: 'https://klosy.pl/grafika/sylwetki210px/new/chleb%20c.png',
  priceRow: {
    PL: 2100,
    EN: 460,
  },
  price: {
    PL: '2100 zł',
    EN: '460 €',
  },
  pricingTable: {
    [KnifePart.Blade]: [
      { type: Metal.Damasteel, price: 0 },
      { type: Metal.K110, price: 100 },
      { type: Metal.N690, price: 0 },
      { type: Metal.M398, price: 0 },
    ],
    [KnifePart.Handle]: [
      { type: Wood.Ironwood, price: 0 },
      { type: Wood.Ebony, price: 0 },
      { type: Wood.Olivewood, price: 0 },
      { type: Wood.PaintedWood, price: 0 },
      { type: Wood.Walnut, price: 0 },
    ],
    [KnifePart.Ferrules]: [{ type: Metal.Damasteel, price: 0 }],
  },
}

export default knife
